import request from "./request";

export const getGlobalSettings = () =>
  request({
    url: "/v2/public/settings",
    method: "get",
  });
export const getCommonData = () =>
  request({
    url: "/common",
    method: "get",
  });
