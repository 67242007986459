import * as actions from "./constants";

export function fetchCommonData() {
  return {
    type: actions.FETCH_COMMON,
  };
}

export function recieveCommonData(response) {
  return {
    type: actions.RECEIVE_COMMON,
    data: response,
  };
}
export function recieveCommonDataFail() {
  return {
    type: actions.RECEIVE_COMMON_FAIL,
  };
}
export function fetchSettings(data) {
  return {
    type: actions.FETCH_SETTINGS,
    data: data,
  };
}

export function recieveGlobalSettings(response) {
  return {
    type: actions.RECEIVE_SETTINGS,
    data: response,
  };
}

export function hydrateSettings(response) {
  return {
    type: actions.HYDRATE_SETTINGS,
    data: response,
  };
}

export const handleFetchSettingsFail = () => {
  return {
    type: actions.SETTINGS_FETCH_FAIL,
  };
};

export function hydrateSettingsSuccess() {
  return {
    type: actions.HYDRATE_SETTINGS_SUCCESS,
  };
}
