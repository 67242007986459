import * as actions from "./constants";

export const initialState = {
  complete: false,
  commonData: {
    data: null,
    complete: false,
    failed: false,
    dataMap: {},
  },
  globalSettings: {
    data: null,
    complete: false,
    failed: false,
  },
};

function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_COMMON:
      return {
        ...state,
        commonData: { ...state.commonData, failed: false, complete: false },
      };
    case actions.RECEIVE_COMMON:
      const map = {};
      for (const [key, value] of Object.entries(action.data)) {
        const items = {};
        value.forEach((item) => {
          items[item.id] = item;
        });

        map[key] = items;
      }
      return {
        ...state,
        commonData: {
          ...state.commonData,
          data: { ...action.data },
          failed: false,
          complete: true,
          dataMap: map,
        },
      };
    case actions.RECEIVE_COMMON_FAIL:
      return {
        ...state,
        commonData: {
          ...state.commonData,
          failed: true,
          complete: true,
        },
      };
    case actions.RECEIVE_SETTINGS:
      return {
        ...state,
        globalSettings: {
          data: [...action.data.settings],
          failed: false,
          complete: true,
        },
      };
    case actions.HYDRATE_SETTINGS_SUCCESS:
      return {
        ...state,
        globalSettings: { ...state.globalSettings, complete: true },
      };
    case actions.SETTINGS_FETCH_FAIL:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          failed: true,
          complete: true,
        },
      };
    default:
      return state;
  }
}

export default settingsReducer;
