import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import saga from "./saga";
import reducer from "./reducer";
import { fetchSettings, fetchCommonData } from "./actions";
import { toastActions } from "pickups-ui-library";

const GlobalSettings = (props) => {
  const { fetchSettings, globalSettings, fetchCommonData, commonData } = props;
  useEffect(() => {
    if (!globalSettings.data) {
      fetchSettings();
    }
  }, [globalSettings.data]);

  useEffect(() => {
    if (!commonData.data) {
      fetchCommonData();
    }
  }, [commonData.data]);

  if (globalSettings.fetchFailed) {
    toastActions.showToast({
      message: "Unable to fetch app settings",
      type: "error",
      noClose: true,
    });
    return null;
  }
  if (commonData.fetchFailed) {
    toastActions.showToast({
      message: "Unable to fetch app common data",
      type: "error",
      noClose: true,
    });
    return null;
  }

  if (!(globalSettings.isComplete && commonData.isComplete)) {
    return null;
  }

  return props.children;
};

const mapStateToProps = (state) => ({
  globalSettings: {
    data: state.settings.globalSettings.data,
    fetchFailed: state.settings.globalSettings.failed,
    isComplete: state.settings.globalSettings.complete,
  },
  commonData: {
    data: state.settings.commonData.data,
    fetchFailed: state.settings.commonData.failed,
    isComplete: state.settings.commonData.complete,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    fetchSettings: () => dispatch(fetchSettings()),
    fetchCommonData: () => dispatch(fetchCommonData()),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: "settings", reducer });
const withSaga = injectSaga({ key: "settings", saga });

export default compose(withReducer, withSaga, withConnect)(GlobalSettings);
