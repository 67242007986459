export const RECEIVE_COMMON = "RECEIVE_COMMON";

export const RECEIVE_COMMON_FAIL = "RECEIVE_COMMON_FAIL";

export const FETCH_COMMON = "FETCH_COMMON";

export const RECEIVE_SETTINGS = "RECEIVE_SETTINGS";

export const FETCH_SETTINGS = "FETCH_SETTINGS";

export const HYDRATE_SETTINGS = "HYDRATE_SETTINGS";

export const SETTINGS_FETCH_FAIL = "SETTINGS_FETCH_FAIL";

export const HYDRATE_SETTINGS_SUCCESS = "HYDRATE_SETTINGS_SUCCESS";
