import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import * as constants from "./constants";
import * as Api from "../../api/settings";

function* fetchSettings(action) {
  try {
    const data = yield call(Api.getGlobalSettings);
    yield put(actions.recieveGlobalSettings(data.data));
  } catch (e) {
    yield put(actions.handleFetchSettingsFail());
  }
}
function* fetchCommonData(action) {
  try {
    const data = yield call(Api.getCommonData);
    yield put(actions.recieveCommonData(data.data.data));
  } catch (e) {
    yield put(actions.recieveCommonDataFail());
  }
}

function* hydrateSettings() {
  yield put(actions.hydrateSettingsSuccess());
}

// Individual exports for testing
export default function* settingsSaga() {
  yield takeLatest(constants.FETCH_SETTINGS, fetchSettings);
  yield takeLatest(constants.FETCH_COMMON, fetchCommonData);
  yield takeLatest(constants.HYDRATE_SETTINGS, hydrateSettings);
}
